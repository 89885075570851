import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
    render() {
        return (
            <div>
                <div className="addbg">
                    <div className="wrapper produkty">
                        <header>
                            <h1 className="logo">
                                <a href="/">Neutrogena</a>
                            </h1>
                            <nav>
                                <a href="/produkty/" className="current">
                                    Produkty
                </a>
                                <span>| </span>
                                <a href="/neutrogena/o-nas/" className="link">
                                    NEUTROGENA<sup>®</sup>
                                </a>
                                <span>| </span>
                                <a href="/budte-krasna/" className="link">
                                    Buďte krásná
                </a>
                                 
                                <span />
                                 
                            </nav>
                        </header>
                        <div className="top">
                            <h2>
                                Produkty
                <br />
                NEUTROGENA <span>®</span>
                            </h2>
                            <h3>Vyberte si produkt na míru vašim potřebám.</h3>
                            <div className="breadcrumb">
                                <a href="/">
                                    NEUTROGENA<sup>®</sup>
                                </a>
                                <span>&gt;</span>
                Produkty
              </div>
                        </div>
                        <div className="content">
                            <div className="filters">
                <p className="head">
                  Filtrování
                  
                </p>
                <div className="filtrate">
                  <div className="middle">
                    <div className="aboutAnchor" />
                    <div>
                      <form
                        id="Form_ProductsFilter"
                        action="/produkty/ProductsFilter"
                        method="post"
                        encType="application/x-www-form-urlencoded"
                      >
                        <p
                          id="Form_ProductsFilter_error"
                          className="message "
                          style={{
                            display: "none"
                          }}
                        />
                        <fieldset>
                          <div className="CompositeField composite nolabel ">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice0"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left filter-title"
                                  htmlFor="Form_ProductsFilter_Nice0"
                                >
                                  TYP PRODUKTU
                                </label>
                                <div className="middleColumn">
                                  <ul
                                    id="Form_ProductsFilter_Nice0"
                                    className="optionset checkboxset"
                                  >
                                    <li className="odd val6">
                                      <label htmlFor="Form_ProductsFilter_Nice0_6" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Telova-mleka/" style={{ color: "white" }}>TĚLOVÁ MLÉKA</a></p>
                                      </label>
                                    </li>
                                    <li className="even val7">
                                      <label htmlFor="Form_ProductsFilter_Nice0_7" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Pece-o-ruce/" style={{ color: "white" }}>PÉČE O RUCE</a></p>
                                      </label>
                                    </li>
                                    <li className="odd val8">
                                      <label htmlFor="Form_ProductsFilter_Nice0_8" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Pece-o-chodidla/" style={{ color: "white" }}>PÉČE O CHODIDLA</a></p>
                                      </label>
                                    </li>
                                    <li className="even val9">
                                      <label htmlFor="Form_ProductsFilter_Nice0_9" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Pece-o-oblicej/" style={{ color: "white" }}>PÉČE O OBLIČEJ</a></p>
                                      </label>
                                    </li>
                                    <li className="even val9">
                                      <label htmlFor="Form_ProductsFilter_Nice0_9" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Cisteni-pleti/" style={{ color: "white" }}>ČIŠTĚNÍ PLETI</a></p>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="CompositeField composite nolabel ">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice1"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left filter-title"
                                  htmlFor="Form_ProductsFilter_Nice1"
                                >
                                  TYP POKOŽKY
                                </label>
                                <div className="middleColumn">
                                  <ul
                                    id="Form_ProductsFilter_Nice1"
                                    className="optionset checkboxset"
                                  >
                                    <li className="odd val14">
                                      <label htmlFor="Form_ProductsFilter_Nice1_14" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Velmi-sucha/" style={{ color: "white" }}>VELMI SUCHÁ</a></p>
                                      </label>
                                    </li>
                                    <li className="even val15">
                                      <label htmlFor="Form_ProductsFilter_Nice1_15" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Sucha/" style={{ color: "white" }}>SUCHÁ</a></p>
                                      </label>
                                    </li>
                                    <li className="odd val16">
                                      <label htmlFor="Form_ProductsFilter_Nice1_16" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Normalni/" style={{ color: "white" }}>NORMÁLNÍ</a></p>
                                      </label>
                                    </li>
                                    <li className="even val17">
                                      <label htmlFor="Form_ProductsFilter_Nice1_17" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Citliva/" style={{ color: "white" }}>CITLIVÁ</a></p>
                                      </label>
                                    </li>
                                    <li className="even val17">
                                      <label htmlFor="Form_ProductsFilter_Nice1_17" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Plet-s-nedokonalostmi/" style={{ color: "white" }}>PLEŤ S NEDOKONALOSTMI</a></p>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="CompositeField composite nolabel custom-filter">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice2"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left filter-title"
                                  htmlFor="Form_ProductsFilter_Nice2"
                                >
                                  PRODUKTOVÁ ŘADA
                                </label>
                                <div className="middleColumn cus-filter">
                                  <ul
                                    id="Form_ProductsFilter_Nice2"
                                    className="optionset checkboxset cus-filter-1"
                                  >
                                    <li className="odd val18">
                                      <label htmlFor="Form_ProductsFilter_Nice2_18" >
                                        <p className="sub-category-filter"><a href="/category/Hydro-boost/" style={{ color: "white" }}>HYDRO BOOST<sup>®</sup></a></p>
                                      </label>
                                    </li>
                                    <li className="even val19">
                                      <label htmlFor="Form_ProductsFilter_Nice2_19" >
                                        <p className="sub-category-filter"><a href="/category/Curcuma-clear/" style={{ color: "white" }}>CURCUMA CLEAR</a></p>
                                      </label>
                                    </li>
                                    <li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/Skin-detox/" style={{ color: "white" }}>SKIN DETOX</a></p>
                                      </label>
                                    </li>
                                    {/* <li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/Cellular-boost/" style={{ color: "white" }}>CELLULAR BOOST</a></p>
                                      </label>
                                    </li> */}
                                    <li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/Bright-boost/" style={{ color: "white" }}>BRIGHT BOOST</a></p>
                                      </label>
                                    </li>
									<li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/Retinol-Boost/" style={{ color: "white" }}>RETINOL BOOST</a></p>
                                      </label>
                                    </li>
                                  </ul>
                                  <ul
                                    id="Form_ProductsFilter_Nice2"
                                    className="optionset checkboxset cus-filter-1"
                                  >  
                                    <li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/Anti-akne/" style={{ color: "white" }}>ANTI-AKNE</a></p>
                                      </label>
                                    </li>
                                    <li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/Norska-receptura/" style={{ color: "white" }}>NORSKÁ RECEPTURA</a></p>
                                      </label>
                                    </li>
                                    <li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/T-gel/" style={{ color: "white" }}>T-GEL<sup>®</sup></a></p>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <input
                            type="hidden"
                            name="SecurityID"
                            defaultValue="e2d20bf1c48b0a151eee0c23b91b10b61f8677e0"
                            className="hidden"
                            id="Form_ProductsFilter_SecurityID"
                          />
                          <div className="clear" />
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
                            <div className="products" >
                                <div className="row">
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/73/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG Refreshingly Clear Peeling 150 ml_3574661498430.png"
                                            alt="NEUTROGENA Refreshingly Clear peeling"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup> Refreshingly Clear peeling
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/143/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG Refreshingly Clear Gel cistici 200 ml_3574660569759.png"
                                            alt="NEUTROGENA Visibly Clear emulze grapefruit 200ml"
                                        />
                                        <span className="txt">
                                            <p className="TableParagraph">
                                                NEUTROGENA<sup>®</sup> Refreshingly Clear čisticí gel
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/75/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG Oil Balancing Peeling 150 ml_3574661498492.png"
                                            alt="NTG SkinDetox TMW 400ml 002f"
                                        />
                                        <span className="txt">
                                            <p>NEUTROGENA<sup>®</sup> Oil Balancing peeling</p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/76/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG Oil Balancing Gel cistici 200 ml_3574661039725.png"
                                            alt="Oil Balancing čisticí gel"
                                        />
                                        <span className="txt">
                                            <p>NEUTROGENA<sup>®</sup>&nbsp;Oil Balancing čisticí gel</p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/78/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG Clear Defend Wash + Maska 150 ml_3574661499666.png"
                                            alt="NEUTRIGENA spot proofing mask 2v1"
                                        />
                                        <span className="txt">
                                            <p>NEUTROGENA<sup>®</sup>&nbsp;Clear & Defend čisticí emulze a maska</p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    
                                </div>
                                <div className="row">
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/77/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG Clear Defend Krem hydratacni 50 ml_3574661332581.png"
                                            alt="NEUTROGENA Clear Defend Krem hydratacni"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup>&nbsp;Clear &amp; Defend &nbsp;hydratační krém bez obsahu oleje
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/79/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG Clear Defend Peeling 150 ml_3574661332505.png"
                                            alt="NEUTROGENA spot proofing smoothing scrub"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup>&nbsp;Clear &amp; Defend peeling
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/82/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG Clear Defend Gel cistici 200 ml_3574661333564.png"
                                            alt="NEUTROGENA Clear Defend Gel cistici"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup>&nbsp;Clear &amp; Defend čisticí gel
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/145/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG_3574661520353_EMEA_VCLR_RAPID_CLR_TREAT_15ML_000.png"
                                            alt="NEUTROGENA Clear Defend Krem hydratacni"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup>&nbsp;Clear &amp; Defend gel pro ošetření pupínků
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="fb_3"></div>
                    </div>
                </div>
                <Footer></Footer>
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
                    }}
                />
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            "\nvar address = ' /produkty/ ';\n$(\"#Form_ProductsFilter_action_ClearAction\").click(function() {\n$('input').attr('checked', false);\n$('label.checked').attr('class', '');\nwindow.location = (address);\n});\n$(\"#allproducts\").click(function() {\nwindow.location = (address);\n});\n"
                    }}
                />
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
                    }}
                />

            </div>
        );
    }
}

export default Page;
